import { defineStore } from "pinia"
import {
   logInfo,
   validateNewBackgroundColorVariation,
   validateNewFontColorVariation,
   validateNewFontSizeVariation,
   validateNewURLVariation,
} from "../utils"
import {
   BasicEditorVariable,
   ChangeConfigEvent,
   ElementPayload,
   ErrorForRender,
   InitEvent,
   OutboundEvent,
} from "../types/visual-editor"
import {
   deleteVariable,
   getVariablesByProjectId,
} from "../services/adminBackendClient"
import { useProjectStore } from "./projectStore"
import { DBVariable, EditableVariable } from "../types"
import {
   buildHumanReadableName,
   buildKey,
} from "../components/visual-editor/basic-editors/shared"
import { ValueChoice } from "../types/variable"

const buildMessage = (event: OutboundEvent) => {
   return JSON.stringify(event)
}

type VisualEditorState = {
   offset: [number, number]
   selectedElement: ElementPayload | null
   highlightColor: string
   url: string | undefined
   mode: "ezbot" | "interactive"
   status: "loading" | "ready" | "error"
   iframe: HTMLIFrameElement | null
   view: "basic" | "advanced"
   highlightEnabled: boolean
   shuffleEnabled: boolean
   activeVariable: BasicEditorVariable | null
   errors: ErrorForRender[]
   userInput: string | null
   originalElements: ElementPayload[] | null
   sendMessagesTo: "iframe" | "parent"
}

const defaultState: VisualEditorState = {
   offset: [100, 100],
   url: undefined,
   highlightColor: "rgba(158, 66, 245, 0.5)",
   selectedElement: null,
   mode: "ezbot",
   status: "loading",
   view: "basic",
   iframe: null,
   highlightEnabled: true,
   shuffleEnabled: true,
   activeVariable: null,
   errors: [],
   userInput: null,
   originalElements: null,
   sendMessagesTo: "iframe",
}

export const useVisualEditorStore = defineStore("visualEditorStore", {
   state: (): VisualEditorState => {
      return defaultState
   },
   actions: {
      async toggleMode() {
         if (!this.iframe) {
            logInfo("iframe not found. Cannot toggle mode")
            return
         }
         this.selectedElement = null
         this.mode = this.mode === "ezbot" ? "interactive" : "ezbot"
         this.changeSDKConfig(false)
      },
      toggleView() {
         this.view = this.view === "basic" ? "advanced" : "basic"
      },
      toggleHighlight() {
         this.highlightEnabled = !this.highlightEnabled
         const msg = buildMessage({
            sender: "ezbotVisualEditor",
            type: "changeConfig",
            mode: this.mode,
            config: {
               highlightColor: this.highlightColor,
               highlightEnabled: this.highlightEnabled,
               shuffleEnabled: this.shuffleEnabled,
            },
         })
         this.postMessage(msg)
      },
      toggleShuffle() {
         this.shuffleEnabled = !this.shuffleEnabled
         const msg = buildMessage({
            sender: "ezbotVisualEditor",
            type: "changeConfig",
            mode: this.mode,
            config: {
               highlightColor: this.highlightColor,
               highlightEnabled: this.highlightEnabled,
               shuffleEnabled: this.shuffleEnabled,
            },
         })
         this.postMessage(msg)
      },
      async initVisualEditorSupport() {
         // tell the sdk to add styles, listeners, etc
         logInfo("Sending Init message to SDK.")
         const projectStore = useProjectStore()
         const variablesResp = await getVariablesByProjectId(
            projectStore.projectId!,
         )
         const variables = variablesResp.data
         const msg = buildMessage({
            sender: "ezbotVisualEditor",
            type: "init",
            mode: this.mode,
            config: {
               highlightColor: this.highlightColor,
               highlightEnabled: this.highlightEnabled,
               shuffleEnabled: this.shuffleEnabled,
            },
            variables,
         } as InitEvent)
         this.status = "loading"
         this.postMessage(msg)
      },
      postMessage(message: string) {
         if (this.iframe) {
            // message as string
            const msg = JSON.parse(message)

            if (this.sendMessagesTo === "parent") {
               // When injecting our snippet into a page via chrome extension,
               // the snippet creates a listener on the parent instead of the iframe
               // so we need to send the message to the parent
               logInfo(`sending message to SELF with type: `, msg.type)
               window.postMessage(message, "*")
            } else {
               // When a page is running our sdk or snippet in their source code,
               // our sdk/snippet creates a listener on the iframe
               // so we need to send the message to the iframe
               logInfo(`Sending message to SDK with type: `, msg.type)
               this.iframe.contentWindow?.postMessage(message, "*")
            }
         } else {
            logInfo(`iframe not found. Could not post message: ${message}`)
         }
      },
      resetPosition() {
         this.offset = [100, 100]
      },
      async sendVariablesToSDK(variables?: DBVariable[]) {
         let variablesToSend: DBVariable[] = []
         if (!variables) {
            variablesToSend = useProjectStore().variables
         } else {
            variablesToSend = variables
         }
         const msg = buildMessage({
            sender: "ezbotVisualEditor",
            type: "changeVariables",
            payload: variablesToSend,
         })
         this.postMessage(msg)
      },
      async changeSDKConfig(includeVariables: boolean) {
         const baseSDKConfig = {
            highlightColor: this.highlightColor,
            highlightEnabled: this.highlightEnabled,
            shuffleEnabled: this.shuffleEnabled,
         }
         const wrapper = {
            type: "changeConfig",
            mode: this.mode,
            config: baseSDKConfig,
         } as ChangeConfigEvent
         const event: OutboundEvent = wrapper
         if (includeVariables) {
            event.variables = useProjectStore().variables
         }
         const msg = buildMessage(event)
         this.postMessage(msg)
      },
      handleElementClicked(element: ElementPayload) {
         this.selectedElement = element
         this.activeVariable = null
      },
      persistOriginalElements(elements: ElementPayload[]) {
         this.originalElements = elements
      },
      async deleteVariable(id: number, refreshVariables: boolean) {
         const projectStore = useProjectStore()
         const visualEditorStore = useVisualEditorStore()
         const variables = projectStore.variables
         if (!variables) {
            logInfo(`No variables found. Cannot delete variable with id: ${id}`)
            return
         }

         await deleteVariable(id)
         if (refreshVariables) {
            visualEditorStore.refreshVariables()
         }
      },
      async deleteActiveVariable() {
         if (!this.activeVariable) {
            logInfo("No active variable found")
            return
         }
         if (!this.activeVariable.id) {
            logInfo("No active variable id found")
            return
         }
         await this.deleteVariable(this.activeVariable.id, true)
         this.activeVariable = this.buildNewBasicEditorVariable(
            this.activeVariable,
         )
      },
      async refreshVariables() {
         logInfo("Refreshing variables")
         const projectStore = useProjectStore()
         const variablesResp = await getVariablesByProjectId(
            projectStore.projectId!,
         )

         projectStore.variables = variablesResp.data
         this.sendVariablesToSDK()
      },
      setDefaultOnActiveVariable(val: string) {
         if (!this.activeVariable) {
            logInfo("No active variable found")
            return
         }
         if (!this.activeVariable.allowedValues) {
            this.activeVariable.allowedValues = []
         }

         // If there's a current defaultValue, move it to allowedValues
         const currentDefaultValue = this.activeVariable.defaultValue
         if (currentDefaultValue) {
            this.activeVariable.allowedValues =
               this.activeVariable.allowedValues.concat(currentDefaultValue)
         }

         // remove new defaultValue from allowedValues if it's there
         this.activeVariable.allowedValues =
            this.activeVariable.allowedValues.filter((v) => v !== val)

         // set new defaultValue as defaultValue on activeVariable
         this.activeVariable!.defaultValue = val
      },
      removeValueFromActiveVariable(val: string) {
         if (!this.activeVariable) {
            logInfo("No active variable found")
            return
         }

         const allowedValuesContainsVal =
            this.activeVariable.allowedValues.includes(val)
         if (allowedValuesContainsVal) {
            this.activeVariable.allowedValues =
               this.activeVariable.allowedValues.filter((v) => v !== val)
         }
         const defaultValueIsVal = this.activeVariable.defaultValue === val
         if (defaultValueIsVal) {
            this.activeVariable.defaultValue = null
         }
      },
      addValueToActiveVariable(val: string) {
         if (!this.activeVariable) {
            logInfo("No active variable found")
            return
         }

         // Add value as a default value if there are no allowed values
         const defaultValue = this.activeVariable?.defaultValue
         if (!defaultValue) {
            this.activeVariable!.defaultValue = val
            return
         }

         if (!this.activeVariable.allowedValues) {
            this.activeVariable.allowedValues = []
         }

         // otherwise add it as an allowed value
         this.activeVariable.allowedValues.push(val)
      },
      validateNewAllowedValue(val: string): string | boolean {
         if (!this.activeVariable) {
            logInfo("No active variable found")
            return false
         }
         switch (this.activeVariable.action) {
            case "setText":
               return true
            case "setFontSize":
               return validateNewFontSizeVariation(val)
            case "setFontColor":
               return validateNewFontColorVariation(val)
            case "setBackgroundColor":
               return validateNewBackgroundColorVariation(val)
            case "setHref":
               return validateNewURLVariation(val)
         }
         return true
      },
      validateActiveVariable(): boolean {
         if (!this.activeVariable) {
            logInfo("No active variable found")
            return false
         }
         // Not valid if there are less than 2 total values
         const dv = this.activeVariable.defaultValue
         let allValues = this.activeVariable.allowedValues
         if (dv) {
            allValues = allValues.concat(dv)
         }
         if (allValues.length < 2) {
            this.errors.push({
               type: "validationMessage",
               msg: "Must have at least two values",
            })
            return false
         }

         // Not valid if there's still text in the Add Allowed Value input
         if (this.userInput && this.userInput.length > 1) {
            this.errors.push({
               type: "validationMessage",
               msg: "Please add your value before saving.",
            })
            return false
         }
         // Not valid if there's no default value
         if (!this.activeVariable.defaultValue) {
            this.errors.push({
               type: "validationMessage",
               msg: "Must have a default value",
            })
            return false
         }

         return true
      },
      resetState() {
         this.$reset()
      },
      async saveActiveVariable() {
         const projectStore = useProjectStore()
         if (!this.validActiveVariable) {
            logInfo("Active variable is not valid, did not save.")
            return
         }
         if (this.shouldUpdateVariable) {
            const updatedVariable = this.buildUpdatedVariable()
            if (!updatedVariable) {
               logInfo("Failed to build updated variable, did not save.")
               return
            }
            await projectStore.updateVariable(
               this.matchingVariableToActiveVariable!.id,
               updatedVariable,
            )
            return
         }

         const newVariable = this.buildNewVariableForSubmission()
         if (!newVariable) {
            logInfo("Failed to build new variable, did not save.")
            return
         }
         await projectStore.createVariable(newVariable)
         await this.refreshVariables()
         this.activeVariable = this.matchingBasicEditorVariable
      },
      buildNewBasicEditorVariable(
         variable: BasicEditorVariable,
      ): BasicEditorVariable | null {
         const selector = this.selectedElement?.selector
         if (!selector) {
            logInfo("No selector found, did not build new variable.")
            return null
         }
         const action = variable.action
         if (!action) {
            logInfo("No action found, did not build new variable.")
            return null
         }

         return {
            id: null,
            action: action,
            defaultValue: null,
            allowedValues: [],
            selector: selector,
            selected: true,
         } as BasicEditorVariable
      },
      buildNewSetVisibilityBasicEditorVariable(): BasicEditorVariable {
         return {
            action: "setVisibility",
            defaultValue: "show",
            allowedValues: ["hide"],
            label: "Visibility",
            selector: this.selectedElement!.selector,
            selected: true,
         } as BasicEditorVariable
      },
      buildNewVariableForSubmission(): EditableVariable | null {
         const projectStore = useProjectStore()
         if (!this.validActiveVariable) {
            logInfo("Active variable is not valid, did not build new variable.")
            return null
         }
         const selector = this.selectedElement?.selector
         if (!selector) {
            logInfo("No selector found, did not build new variable.")
            return null
         }
         const action = this.activeVariable!.action
         return {
            key: buildKey(),
            type: "visual",
            version: "0.2",
            projectId: projectStore.projectId,
            humanReadableName: buildHumanReadableName(selector, action),
            defaultValue: this.activeVariable!.defaultValue!,
            config: {
               action: action,
               selector: selector,
            },
            constraints: {
               enumerables: this.activeVariable!.allowedValues,
            },
            constraintsVersion: "0.1",
         } as EditableVariable
      },

      buildUpdatedVariable(): EditableVariable | null {
         if (!this.validActiveVariable) {
            logInfo(
               "Active variable is not valid, did not build updated variable.",
            )
            return null
         }
         const selector = this.selectedElement?.selector
         if (!selector) {
            logInfo("No selector found, did not build new variable.")
            return null
         }
         const action = this.activeVariable!.action
         const projectStore = useProjectStore()
         if (!this.matchingVariableToActiveVariable) {
            logInfo(
               "No matching variable found, did not build updated variable.",
            )
            return null
         }
         return {
            id: this.matchingVariableToActiveVariable!.id,
            key: this.matchingVariableToActiveVariable!.key,
            type: "visual",
            version: "0.2",
            projectId: projectStore.projectId,
            humanReadableName: buildHumanReadableName(selector, action),
            defaultValue: this.activeVariable!.defaultValue!,
            config: {
               action: action,
               selector: selector,
            },
            constraints: {
               enumerables: this.activeVariable!.allowedValues,
            },
            constraintsVersion: "0.1",
         } as EditableVariable
      },
      selectVariable(variable: BasicEditorVariable) {
         // if the active variable has an id, it exists in the database and we
         // should prepare to update it.
         if (!variable?.id) {
            this.activeVariable = this.buildNewBasicEditorVariable(variable)
         }

         const matchingVariable = this.matchingVariables.find((v) => {
            return (
               v.config?.selector === variable.selector &&
               v.config?.action === variable.action
            )
         })
         if (matchingVariable) {
            this.activeVariable = {
               id: matchingVariable!.id,
               action: variable.action,
               defaultValue: matchingVariable?.defaultValue || null,
               allowedValues: matchingVariable?.constraints?.enumerables || [],
               selector: variable.selector,
               selected: true,
            } as BasicEditorVariable
            return
         }

         if (variable.action === "setVisibility") {
            this.activeVariable =
               this.buildNewSetVisibilityBasicEditorVariable()
            return
         }

         this.activeVariable = this.buildNewBasicEditorVariable(variable)
      },
      async setupVisualEditor() {
         const projectStore = useProjectStore()
         const pi = projectStore.projectId
         if (!pi) {
            logInfo("No project id found, did not setup visual editor.")
            return
         }
         const ps = await projectStore.getProjectSettings(pi)
         const u = ps.settings.visualEditor?.defaultUrl
         if (!u || u == "") {
            logInfo(
               "No url found in project settings, did not setup visual editor.",
            )
            return
         }
         this.url = u
      },
   },
   getters: {
      getVariablesFromProject: (): DBVariable[] => {
         const projectStore = useProjectStore()
         return projectStore.variables || []
      },
      errorsForRender: (state: VisualEditorState): ErrorForRender[] => {
         if (!state.errors || state.errors.length === 0) {
            return []
         }
         const errors: ErrorForRender[] = []
         if (!state.activeVariable) {
            return []
         }
         if (!state.activeVariable.action) {
            return []
         }
         const test = state.userInput ? state.userInput : ""
         let valid: boolean | string
         switch (state.activeVariable.action) {
            case "setText":
               break
            case "setFontSize":
               valid = validateNewFontSizeVariation(test)
               if (!valid) {
                  const efr = {
                     type: "validationMessage",
                     msg: valid as string,
                  } as ErrorForRender
                  errors.push(efr)
               }
               break
            case "setFontColor":
               valid = validateNewFontColorVariation(test)
               if (!valid) {
                  const efr = {
                     type: "validationMessage",
                     msg: valid as string,
                  } as ErrorForRender
                  errors.push(efr)
               }
               break
            case "setBackgroundColor":
               valid = validateNewBackgroundColorVariation(test)
               if (!valid) {
                  const efr = {
                     type: "validationMessage",
                     msg: valid as string,
                  } as ErrorForRender
                  errors.push(efr)
               }
               break
            case "setHref":
               valid = validateNewURLVariation(test)
               if (!valid) {
                  const efr = {
                     type: "validationMessage",
                     msg: valid as string,
                  } as ErrorForRender
                  errors.push(efr)
               }
               break
         }
         return errors
      },
      valid(state: VisualEditorState): boolean {
         if (state.errors.length > 0) {
            return false
         }
         // Not valid if there's still text in the Add Allowed Value input
         if (state.userInput && state.userInput.length > 1) {
            return false
         }
         if (!this) {
            return false
         }
         if (!this.validActiveVariable) {
            return false
         }

         return true
      },
      validActiveVariable: (state: VisualEditorState): boolean => {
         if (!state.activeVariable) {
            return false
         }
         // Not valid if there are less than 2 total values
         const dv = state.activeVariable.defaultValue
         let allValues = state.activeVariable.allowedValues
         if (dv) {
            allValues = allValues.concat(dv)
         }
         if (allValues.length < 2) {
            return false
         }

         // Not valid if there's no default value
         if (!state.activeVariable.defaultValue) {
            return false
         }

         return true
      },
      shouldUpdateVariable(): boolean {
         if (!this.matchingVariableToActiveVariable) {
            return false
         }

         return true
      },
      matchingVariables: (state: VisualEditorState): DBVariable[] => {
         const elementSelector = state.selectedElement?.selector
         if (!elementSelector) {
            return []
         }
         const projectStore = useProjectStore()
         const variables = projectStore.variables
         const matches = variables.filter(
            (variable) =>
               variable.config?.selector === elementSelector &&
               variable.config?.action === state.activeVariable?.action,
         )
         if (!matches) {
            return []
         }
         return matches
      },
      matchingVariableToActiveVariable(
         state: VisualEditorState,
      ): DBVariable | null {
         if (state.activeVariable === null) return null
         if (this.matchingVariables.length == 0) return null
         if (!state.activeVariable.selector) {
            return null
         }
         const match = this.matchingVariables.find((variable) => {
            if (!variable.config) {
               return false
            }
            return (
               variable.config!.selector === state.activeVariable!.selector &&
               variable.config!.action === state.activeVariable!.action
            )
         })
         if (!match) {
            return null
         }
         if (!match.config) {
            return null
         }
         return match
      },
      matchingVariableWithOuterHTMLAction(
         state: VisualEditorState,
      ): DBVariable | null {
         if (state.selectedElement === null) return null
         if (this.matchingVariables === null) return null
         const match = this.matchingVariables.find(
            (variable) => variable.config?.action === "setOuterHTML",
         )
         if (!match) {
            return null
         }
         if (!match.config) {
            return null
         }
         return match
      },
      numVariationsOnActiveVariable: (state: VisualEditorState): number => {
         if (!state.activeVariable) {
            return 0
         }
         const defaultValue = state.activeVariable.defaultValue ? 1 : 0
         const variations = state.activeVariable.allowedValues
            ? state.activeVariable.allowedValues.length
            : 0
         return defaultValue + variations
      },
      allValues: (state: VisualEditorState): string[] => {
         if (!state.activeVariable) {
            return []
         }
         const allowedValues = state.activeVariable.allowedValues || []
         const defaultValue = state.activeVariable.defaultValue

         let returnValues: string[] = []
         if (defaultValue) {
            returnValues = allowedValues.concat(defaultValue)
         } else {
            returnValues = allowedValues
         }

         return returnValues
      },
      allValueChoices(state: VisualEditorState): ValueChoice[] {
         return this.allValues.map((value) => {
            return {
               value: value,
               default: value === state.activeVariable?.defaultValue,
            }
         })
      },
      matchingBasicEditorVariable(): BasicEditorVariable | null {
         const matchingDBVariable = this.matchingVariableToActiveVariable
         if (!matchingDBVariable) {
            return null
         }
         return {
            id: matchingDBVariable.id,
            action: matchingDBVariable.config?.action,
            defaultValue: matchingDBVariable.defaultValue,
            allowedValues: matchingDBVariable.constraints?.enumerables,
            selector: matchingDBVariable.config?.selector,
         } as BasicEditorVariable
      },
   },
   persist: true,
})
