import { defineStore } from "pinia"
import { getDashboardByActiveProject } from "@/services/adminBackendClient"
import { useProjectStore } from "./projectStore"
import { toast } from "vue3-toastify"
import { collectError } from "@utils/errors"
import {
   HourOfDay,
   SessionMetricsV2,
   SessionStatsV2,
   TimeBucketV2,
   TopN,
   VariableSnapshot,
} from "@/types/dashboard"
import dashboard from "@/mocks/dashboard"
import { date } from "quasar"
// destructuring to keep only what is needed
const { getDateDiff } = date

type DashboardState = {
   loading: boolean
   trace_id?: string
   query_start_date?: number
   start_date?: number
   query_end_date?: number
   end_date?: number
   as_of_time?: number
   agg_level?: string
   stats?: SessionStatsV2
   topN?: TopN
   variable_snapshot?: VariableSnapshot
   time_series?: TimeBucketV2
   hour_of_day?: HourOfDay[]
}

export const useDashboardStore = defineStore("dashboardStore", {
   state: (): DashboardState => {
      return {
         loading: false,
         trace_id: undefined,
         query_start_date: undefined,
         start_date: undefined,
         query_end_date: undefined,
         end_date: undefined,
         as_of_time: undefined,
         agg_level: undefined,
         stats: undefined,
         topN: undefined,
         variable_snapshot: undefined,
         time_series: undefined,
         hour_of_day: undefined,
      }
   },
   persist: true,
   actions: {
      clearState() {
         this.loading = true
         this.trace_id = undefined
         this.query_start_date = undefined
         this.start_date = undefined
         this.query_end_date = undefined
         this.end_date = undefined
         this.as_of_time = undefined
         this.agg_level = undefined
         this.time_series = undefined
         this.hour_of_day = undefined
         this.stats = undefined
         this.topN = undefined
         this.variable_snapshot = undefined
      },
      getStartDate(): number {
         const now = new Date()
         now.setDate(now.getDate() - 30)
         return Math.floor(
            (this.query_start_date ? this.query_start_date! : now.getTime()) /
               1000,
         )
      },
      getEndDate(): number {
         const now = new Date()
         now.setDate(now.getDate() + 1)
         return Math.floor(
            (this.query_end_date ? this.query_end_date! : now.getTime()) / 1000,
         )
      },
      async getDashboard(
         startDate: string,
         endDate: string,
      ): Promise<SessionMetricsV2> {
         const startDt = Date.parse(startDate)
         const endDt = Date.parse(endDate)
         this.query_start_date = startDt
         this.query_end_date = endDt
         return this.updateDashboard(
            this.variable_snapshot ? this.variable_snapshot!.ids : [],
         )
      },
      async getCombos(
         startAt: number,
         count: number,
         sortBy: string,
         descending: boolean,
      ) {
         if (this.topN) {
            this.topN.start_at = startAt
            this.topN.count = count
         }

         return this.updateDashboard(
            this.variable_snapshot ? this.variable_snapshot!.ids : [],
            startAt,
            count,
            sortBy,
            descending,
         )
      },
      async updateDashboard(
         variableIds: number[],
         startAt?: number,
         count?: number,
         sortBy?: string,
         descending?: boolean,
      ): Promise<SessionMetricsV2> {
         this.loading = true
         const projectStore = useProjectStore()
         const projectId = projectStore.projectId
         if (!projectId) throw new Error("Project ID is not set")
         let response = null
         try {
            if (import.meta.env.VITE_MOCK_SESSION_STATS == "true") {
               response = { data: dashboard }
            } else {
               response = await getDashboardByActiveProject(
                  this.getStartDate(),
                  this.getEndDate(),
                  this.agg_level ? this.agg_level : "24h",
                  variableIds,
                  startAt ? startAt : 0,
                  count ? count : 25,
                  sortBy ? sortBy : "id",
                  descending ? descending : false,
               )
            }
         } catch (error) {
            collectError(error as Error)
            this.loading = false
            toast.error("Error occurred while fetching session metrics")
            throw error
         }
         this.trace_id = response.data.trace_id
         this.start_date = response.data.start_date
         this.end_date = response.data.end_date
         this.as_of_time = response.data.as_of_time
         this.agg_level = response.data.agg_level
         this.time_series = response.data.time_series
         this.hour_of_day = response.data.hour_of_day
         this.stats = response.data.stats
         this.topN = response.data.topN
         this.variable_snapshot = response.data.variable_snapshot
         this.loading = false

         return response.data
      },
   },
   getters: {
      daysBack(state): number {
         if (state.query_start_date) {
            return getDateDiff(Date.now(), state.query_start_date, "days")
         } else {
            return 30
         }
      },
      hasVariants(state): boolean {
         return (
            state.topN?.combinations !== null &&
            state.topN?.combinations !== undefined
         )
      },
   },
})
