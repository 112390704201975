type ChartJSColor = {
   background: string
}

const blueChartColors: Array<ChartJSColor> = [
   {
      background: "#0071FF",
   },
   {
      background: "#3388FF",
   },
   {
      background: "#66A6FF",
   },
   {
      background: "#99C3FF",
   },
   {
      background: "#CCE0FF",
   },
   {
      background: "#E6F0FF",
   },
   {
      background: "#F0F7FF",
   },
   {
      background: "#F5FAFF",
   },
   {
      background: "#F9FCFF",
   },
   {
      background: "#FCFEFF",
   },
   {
      background: "#FEFFFF",
   },
   {
      background: "#FFFFFF",
   },
   {
      background: "#FFFFFF",
   },
   {
      background: "#FFFFFF",
   },
   {
      background: "#FFFFFF",
   },
]

const greenChartColors = [
   {
      background: "#00B080",
   },
   {
      background: "#1ACFA3",
   },
   {
      background: "#34D9C7",
   },
   {
      background: "#4EE2DB",
   },
   {
      background: "#68EDF0",
   },
   {
      background: "#82F8F4",
   },
   {
      background: "#9CFFFF",
   },
   {
      background: "#B6FFFF",
   },
   {
      background: "#D0FFFF",
   },
   {
      background: "#EAFFFF",
   },
   {
      background: "#FFFFFF",
   },
   {
      background: "#FFFFFF",
   },
   {
      background: "#FFFFFF",
   },
   {
      background: "#FFFFFF",
   },
]

const contrastColors = [
   {
      background: "#003F5C",
   },
   {
      background: "#2c4875",
   },
   {
      background: "#58508D",
   },
   {
      background: "#8A508F",
   },
   {
      background: "#BC5090",
   },
   {
      background: "#FF6361",
   },
   {
      background: "#FF8531",
   },
   {
      background: "#FFA600",
   },
   {
      background: "#101419",
   },
]

const retroMetroColors = [
   "#ea5545",
   "#f46a9b",
   "#ef9b20",
   "#edbf33",
   "#ede15b",
   "#bdcf32",
   "#87bc45",
   "#27aeef",
   "#274fef",
   "#b33dc6",
]

const apexColors = [
   "#008FFB",
   "#00E396",
   "#FEB019",
   "#FF4560",
   "#775DD0",
   "#546E7A",
   "#3F51B5",
   "#03A9F4",
   "#4CAF50",
   "#F9CE1D",
   "#FF9800",
]

const blueGreenColors: Array<ChartJSColor> = []
for (
   let i = 0;
   i < blueChartColors.length && i < greenChartColors.length;
   i++
) {
   blueGreenColors.push(blueChartColors[i])
   blueGreenColors.push(greenChartColors[i])
}

const getRetroMetroColor = (i: number) => {
   return retroMetroColors[i % retroMetroColors.length]
}

const getApexColor = (i: number) => {
   return apexColors[i % apexColors.length]
}

export {
   blueChartColors,
   greenChartColors,
   blueGreenColors,
   contrastColors,
   retroMetroColors,
   getRetroMetroColor,
   apexColors,
   getApexColor,
}
