<template>
   <QBtn color="primary" :outline="datePickerOpened" icon-right="mdi-calendar">
      <span style="margin-right: 15px">{{ dateRangeStringToDisplay }}</span>
      <QPopupProxy
         v-model="popUpOpen"
         @before-show="datePickerOpened = true"
         @before-hide="datePickerOpened = false"
      >
         <QDate v-model="dateRange" range :options="dateOptions">
            <div class="row items-center justify-end q-gutter-sm">
               <QBtn label="Cancel" color="primary" flat v-close-popup />
               <QBtn
                  label="OK"
                  color="primary"
                  flat
                  v-close-popup
                  @click="save"
               />
            </div>
         </QDate>
      </QPopupProxy>
   </QBtn>
</template>
<script setup lang="ts">
import { useSessionMetricsStore } from "@/stores/sessionMetricsStore"
import { QDate, QPopupProxy, QBtn } from "quasar"
import { computed, ref, watch } from "vue"
// we import all of `date`
import { date } from "quasar"
// destructuring to keep only what is needed
const { formatDate } = date
const sessionMetricsStore = useSessionMetricsStore()

const dateOptions = (dateString: string) => {
   const dateToCheck = new Date(dateString)
   const startDate = new Date()
   startDate.setFullYear(startDate.getFullYear() - 1)

   return dateToCheck >= startDate
}

const popUpOpen = ref(false)

const formattedStartDateFromState = computed(() => {
   return formatDate(sessionMetricsStore.query_start_date, "YYYY/MM/DD")
})

const formattedEndDateFromState = computed(() => {
   return formatDate(sessionMetricsStore.query_end_date, "YYYY/MM/DD")
})

const dateRange = ref({
   from: formattedStartDateFromState.value,
   to: formattedEndDateFromState.value,
})

const datePickerOpened = ref(false)

const dateRangeString = computed(() => {
   const { from, to } = dateRange.value || {}
   return from && to
      ? `${formatDate(from, "MM/DD/YYYY")} to ${formatDate(to, "MM/DD/YYYY")}`
      : null
})

const dateRangeStringToDisplay = ref(dateRangeString.value)

const updateDateRangeString = () => {
   dateRangeStringToDisplay.value = dateRangeString.value
}

watch(dateRange, () => {
   if (dateRange.value !== null) {
      updateDateRangeString()
   }
})

const save = async () => {
   if (!dateRange.value?.from || !dateRange.value?.to) {
      dateRange.value = {
         from: formatDate(sessionMetricsStore.query_start_date, "YYYY/MM/DD"),
         to: formatDate(sessionMetricsStore.query_end_date, "YYYY/MM/DD"),
      }
   }
   await sessionMetricsStore.getSessionMetrics(
      dateRange.value.from,
      dateRange.value.to,
   )
}
</script>
