<template>
   <QBtnDropdown
      outline
      color="primary"
      text-color="primary"
      :label="aggLevelLabel"
      size="md"
   >
      <QList>
         <QItem clickable v-close-popup @click="selectAggLevel('24h')">
            <QItemSection>
               <QItemLabel>24h</QItemLabel>
            </QItemSection>
         </QItem>
         <QItem clickable v-close-popup @click="selectAggLevel('12h')">
            <QItemSection>
               <QItemLabel>12h </QItemLabel>
            </QItemSection>
         </QItem>
         <QItem clickable v-close-popup @click="selectAggLevel('6h')">
            <QItemSection>
               <QItemLabel>6h</QItemLabel>
            </QItemSection>
         </QItem>
      </QList>
   </QBtnDropdown>
</template>
<script setup lang="ts">
import { computed } from "vue"
import { useSessionMetricsStore } from "@/stores/sessionMetricsStore"
const sessionMetricsStore = useSessionMetricsStore()

const aggLevelLabel = computed(() => {
   if (sessionMetricsStore.agg_level) {
      return sessionMetricsStore.agg_level
   } else {
      return "Loading..."
   }
})
const selectAggLevel = async (aggLevel: string) => {
   if (aggLevel === "24h") {
      /* empty */
   } else if (aggLevel === "12h") {
      /* empty */
   } else if (aggLevel === "6h") {
      /* empty */
   } else if (aggLevel === "3h") {
      /* empty */
   } else if (aggLevel === "1h") {
      /* empty */
   } else {
      throw new Error("Unsupported aggregation level")
   }
   await sessionMetricsStore.getSessionMetricsAggLevel(aggLevel)
}
</script>
