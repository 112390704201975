<template>
   <div id="layout-container">
      <QLayout view="lhh LpR lff" container>
         <QPageContainer>
            <QPage>
               <!-- <div id="main-container"> -->
               <Toolbox
                  ref="toolbox"
                  @update-iframe-source="onUpdateiFrameSource"
               />
               <div id="iframe-container">
                  <iframe
                     id="iframe"
                     ref="iframe"
                     :src="visualEditorStore.url"
                     width="100%"
                     height="100%"
                  ></iframe>
               </div>
               <!-- </div> -->
            </QPage>
         </QPageContainer>
      </QLayout>
   </div>
</template>
<script setup lang="ts">
import { ref, Ref, onMounted } from "vue"
import { QLayout, QPageContainer, QPage } from "quasar"
import Toolbox from "../components/visual-editor/Toolbox.vue"
import { useVisualEditorStore } from "../stores/visualEditorStore"
import { useProjectStore } from "../stores/projectStore"
const visualEditorStore = useVisualEditorStore()
const projectStore = useProjectStore()

const iframe: Ref<HTMLIFrameElement | null> = ref(null)

const onUpdateiFrameSource = (newVal: string) => {
   visualEditorStore.url = newVal
   visualEditorStore.iframe = iframe.value
   visualEditorStore.initVisualEditorSupport()
}

const getURLFromProjectSettings = async () => {
   const localProjectSettings = await projectStore.getProjectSettings(
      projectStore.projectId!,
   )
   visualEditorStore.url =
      localProjectSettings.settings.visualEditor?.defaultUrl ||
      "https://ezbot.ai"
}
// on mount, add event listener to iframe
onMounted(async () => {
   visualEditorStore.resetPosition()
   visualEditorStore.iframe = iframe.value
   if (visualEditorStore.url === null) {
      await getURLFromProjectSettings()
   }
})
</script>
<style lang="scss" scoped>
#layout-container {
   width: 100%;
   height: 100vh;
}
#iframe-container {
   width: 100%;
   height: 100vh;
   display: flex;
   align-items: center;
   justify-content: center;
}
#main-container {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}
#headline {
   color: $primary;
}
#headline {
   font-family: venir, Helvetica, Arial, sans-serif;
   -webkit-font-smoothing: antialiased;
   font-size: 20px;
   letter-spacing: 0.005em;
   font-weight: 400;
   text-align: left;
   line-height: 1em;
   color: black;
}
#iframe {
   border: none;
}
</style>
