<template>
   <div>
      <h1 class="text-h3 text-left text-bold">You're almost done!</h1>
      <p id="intro-paragraph" class="text-left">
         Your next step is to install the ezbot Web Tracker on your website
         following the instructions below. You will see your dashboard here once
         we start receiving data from your website. Please allow up to 24 hours.
         <a href="https://docs.ezbot.ai/get-started/install/" target="_new"
            >Read our implementation guide here.</a
         >
      </p>
      <p id="project-id" class="text-left text-bold">
         <span>Your Project Id is: </span>{{ projectStore.projectId }}
      </p>
   </div>
</template>
<script setup lang="ts">
import { useProjectStore } from "../../stores"
const projectStore = useProjectStore()
</script>
<style scoped>
#intro-paragraph {
   max-width: 800px;
}
</style>
