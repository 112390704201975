<template>
   <div class="bg-white q-pa-md" id="home-main-container">
      <!-- Header Section -->
      <Header v-if="hasSummaryStatsVariants" />
      <InstallInstructions2 v-else />
      <div id="main-container">
         <!-- Installation Instructions or Stacked Graphs -->
         <InstallInstructions v-if="!hasSummaryStatsVariants" />
         <StackedGraphs v-else />
      </div>
   </div>
</template>
<script setup lang="ts">
// Imports
import { date, useQuasar } from "quasar"
import { useProjectStore, useSessionMetricsStore } from "../stores"
import { onMounted, watch } from "vue"
import Header from "../components/data-explorer/Header.vue"
import InstallInstructions from "../components/data-explorer/InstallInstructions.vue"
import StackedGraphs from "../components/data-explorer/StackedGraphs.vue"
import InstallInstructions2 from "../components/data-explorer/InstallInstructions2.vue"

// Utilities
const { formatDate, subtractFromDate } = date
const $q = useQuasar()

// Stores
const sessionMetricsStore = useSessionMetricsStore()
const projectStore = useProjectStore()

// State
const hasSummaryStatsVariants = sessionMetricsStore.summary_stats?.variants

// Lifecycle Hooks
onMounted(() => {
   // Fetch variables for CombinationDetailsDialog
   projectStore.getActiveOrgProjects()
   projectStore.getVariablesByProjectId()
})

// Subscriptions
sessionMetricsStore.$subscribe((mutation, state) => {
   if (state.loading) {
      $q.loading.show()
   } else {
      $q.loading.hide()
   }
})

// Initial Data Fetching
if (
   !sessionMetricsStore.query_start_date ||
   !sessionMetricsStore.query_end_date
) {
   const startDateString = formatDate(
      subtractFromDate(Date.now(), { days: 30 }),
      "YYYY/MM/DD",
   )

   const endDateString = formatDate(Date.now(), "YYYY/MM/DD")
   await sessionMetricsStore.getSessionMetrics(startDateString, endDateString)
} else {
   await sessionMetricsStore.updateMetrics()
}

// Watchers
watch(
   () => projectStore.projectId,
   async () => {
      if (projectStore.projectId) {
         projectStore.getVariablesByProjectId()
         sessionMetricsStore.updateMetrics()
      }
   },
)
</script>
<style lang="scss" scoped>
#main-container {
   width: 100%;
}
#kpi-container {
   display: flex;
   align-items: center;
   gap: 2em;
   width: 100%;
   padding-top: 1em;
   padding-bottom: 1em;
}
.kpi-card {
   width: 100%;
}
#graphs-container {
   width: 100%;
   display: flex;
   flex-direction: row;
   align-items: center;
   gap: 2em;
}
.graph-card {
   width: 100%;
}

#top-container {
   display: flex;
   justify-content: space-between;
   align-items: center;
   width: 100%;
   padding-top: 1em;
   padding-bottom: 1em;
}
#dropdown-container {
   display: flex;
   gap: 1em;
   max-height: 40px;
}
#chips-container {
   display: flex;
   gap: 1em;
}
#conversion-rate-filter-container {
   display: flex;
   gap: 1em;
   // border: 1px solid rgb(160, 160, 160);
   // border-radius: 5px;
   padding: 1em 2em;
}
@media (max-width: 1400px) {
   #dashboard-title {
      margin-bottom: 0.5em;
   }
   .graph-card {
      width: 100%;
   }
   #kpi-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
   }
   #graphs-container {
      flex-direction: column;
   }
   #dropdown-container {
      margin-bottom: 1em;
   }
   #chips-container {
      width: 100%;
   }
}
#home-main-container {
   min-height: 100vh;
}

@media (max-width: 1200px) {
   #code-snippets-container {
      display: flex;
      flex-direction: column;
   }
   #code-snippets {
      display: none;
   }
   #intro-paragraph {
      display: none;
   }
   #project-id {
      display: none;
   }
}
@media (min-width: 1200px) {
   #mobile-install-instructions {
      display: none;
   }
   #installation-support-card {
      max-width: 600px;
   }
}
#code-snippets-container {
   margin-top: 2em;
   display: flex;
   gap: 20px;
}
#intro-paragraph {
   max-width: 800px;
}
#installation-support-card {
   width: 100%;
}
#graph-controls {
   display: flex;
   justify-content: flex-start;
   margin-bottom: 2em;
   gap: 1em;
   align-items: center;
}
</style>
