<template>
   <AbsoluteConversionRateGraph
      v-if="!props.showConversionRateImprovement && props.chartData"
      :show-legend="props.showLegend"
      :height="props.height"
      :chart-data="props.chartData"
   />
   <ConversionRateImprovementGraph
      v-else-if="props.showConversionRateImprovement && props.chartData"
      :height="props.height"
      :show-legend="props.showLegend"
      :chart-data="props.chartData"
   ></ConversionRateImprovementGraph>
</template>
<script setup lang="ts">
import ConversionRateImprovementGraph from "./ConversionRateImprovementGraph.vue"
import AbsoluteConversionRateGraph from "./AbsoluteConversionRateGraph.vue"
import { PropType } from "vue"
import { ChartData } from "chart.js"
const props = defineProps({
   showConversionRateImprovement: {
      type: Boolean,
      required: true,
   },
   height: {
      type: String,
      required: true,
   },
   showLegend: {
      type: Boolean,
      required: true,
   },
   chartData: {
      type: Object as PropType<ChartData<"line"> | null>,
      required: false,
   },
})
</script>
