import { defineStore } from "pinia"
import {
   getVariablesByProjectId,
   getVariable,
   getAttributesByProjectId,
   getAttribute,
   updateAttribute,
   createAttribute,
   deleteAttribute,
   createVariable,
   updateVariable,
   deleteVariable,
   getProjectSettings,
   createProject,
   deleteProject,
   updateProject,
   updateCurrentProject,
   getProjectsByActiveOrg,
   updateActiveProjectSettings,
} from "../services/adminBackendClient"
import { Attribute, Project, DBVariable } from "../types"
import {
   UpdateAttributePayload,
   CreateAttributePayload,
   UpdateProjectSettingsPayload,
   ProjectSettings,
   EditableVariable,
} from "../types"
import { collectError, logInfo } from "../utils"
import { Combination, PlaceValueVariable } from "@/types/variable"
import {
   createReversedPlaceValues,
   decodeCombination,
} from "@/utils/combinationDecoder"
import { getOrgId } from "@/services/clerk"
import { AxiosError } from "axios"

type ProjectState = {
   projectId: number | null
   projectName: string | null
   projects: Array<Project>
   variables: Array<DBVariable>
   attributes: Array<Attribute>
   previousCreateVariableType: {
      label: string
      value: string
   } | null
}

export const useProjectStore = defineStore("projectStore", {
   state: (): ProjectState => {
      return {
         projectId: null,
         projectName: null,
         projects: [],
         variables: [],
         attributes: [],
         previousCreateVariableType: null,
      }
   },
   persist: true,
   actions: {
      async getVariablesByProjectId(
         projectId?: number,
      ): Promise<Array<DBVariable>> {
         let data: Array<DBVariable>
         const pi = projectId || this.projectId
         if (!pi) {
            logInfo("No projectId provided or found. Cannot get variables")
            return []
         }

         try {
            logInfo("Getting variables for projectId: " + pi)
            const variablesResp = await getVariablesByProjectId(pi)
            if (variablesResp.data == null) {
               return []
            }
            data = variablesResp.data
            this.variables = data
            return data
         } catch (error) {
            collectError(error as Error)
            data = []
            return data
         }
      },
      async getVariable(id: number): Promise<DBVariable> {
         const response = await getVariable(id)
         return response.data
      },
      async getAttributesByProjectId(
         projectId: number,
      ): Promise<Array<Attribute>> {
         let response
         let data: Array<Attribute>

         try {
            response = await getAttributesByProjectId(projectId)
            if (response.data == null) {
               return []
            }
            data = response.data
         } catch (error) {
            collectError(error as Error)
            data = []
         }

         this.attributes = data
         return data
      },
      async getAttribute(id: number): Promise<Attribute> {
         const response = await getAttribute(id)
         return response.data
      },
      async updateAttribute(
         id: number,
         payload: UpdateAttributePayload,
      ): Promise<Attribute> {
         const response = await updateAttribute(id, payload)
         return response.data
      },
      async updateVariable(
         id: number,
         payload: EditableVariable,
      ): Promise<DBVariable> {
         const response = await updateVariable(id, payload)
         return response.data
      },
      async createAttribute(
         payload: CreateAttributePayload,
      ): Promise<Attribute> {
         const response = await createAttribute(payload)
         return response.data
      },
      async deleteAttribute(id: number): Promise<boolean> {
         const response = await deleteAttribute(id)
         return response.data
      },
      async createVariable(payload: EditableVariable) {
         const response = await createVariable(payload)
         return response.data
      },
      async deleteVariable(id: number): Promise<boolean> {
         const response = await deleteVariable(id)
         return response.data
      },
      async getProjectSettings(projectId: number): Promise<ProjectSettings> {
         const response = await getProjectSettings(projectId)
         return response.data
      },
      async updateProjectSettings(payload: UpdateProjectSettingsPayload) {
         const response = await updateActiveProjectSettings(payload)
         return response.data
      },
      async getActiveOrgProjects(): Promise<Array<Project>> {
         try {
            const response = await getProjectsByActiveOrg()
            this.projects = response.data
            return response.data
         } catch (error) {
            collectError(error as Error)
            if ((error as AxiosError)?.response?.status === 404) {
               this.projects = []
               return []
            }
         }
         return []
      },
      decodeCombination(combination: number): Combination {
         return decodeCombination(this.reversedVariablePlaceValues, combination)
      },
      getName(variableId: number): string {
         const variable = this.variables.find((x) => x.id == variableId)
         if (variable != undefined) {
            return variable.humanReadableName
         } else {
            return ""
         }
      },
      getValue(variableId: number, index: number): string {
         const variable = this.variables.find((x) => x.id == variableId)
         if (variable != undefined) {
            if (index == 1) {
               return variable.defaultValue
            } else {
               const constraints = variable.constraints.enumerables.sort()
               return constraints[index - 2]
            }
         } else {
            return ""
         }
      },
      async createProject(
         humanReadableName: string,
         orgId: string,
      ): Promise<Project> {
         const response = await createProject(humanReadableName, orgId)
         return response.data
      },
      async deleteProject(projectId: number): Promise<boolean> {
         const response = await deleteProject(projectId)
         return response.data
      },
      async updateProject(
         orgId: string,
         projectId: number,
         humanReadableName: string,
      ): Promise<Project> {
         const response = await updateProject(
            projectId,
            humanReadableName,
            orgId,
         )
         const p = response.data
         this.projectId = p.id
         this.projectName = p.humanReadableName
         return p
      },
      async updateCurrentProject(humanReadableName: string): Promise<Project> {
         const response = await updateCurrentProject(humanReadableName)
         const p = response.data
         this.projectId = p.id
         this.projectName = p.humanReadableName
         const projects = await this.getActiveOrgProjects()
         this.projects = projects
         return p
      },
   },
   getters: {
      reversedVariablePlaceValues(state): PlaceValueVariable[] {
         return createReversedPlaceValues(state.variables)
      },
      async activeOrgHasCurrentProject(): Promise<boolean> {
         const orgId = await getOrgId()
         if (!this.projectId) {
            return false
         }

         if (!orgId) {
            return false
         }

         if (!this.projects.find((p) => p.id === this.projectId)) {
            return false
         }

         return true
      },
   },
})
