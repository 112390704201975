import { CreateAttribute } from "@components/index"
import {
   Attributes,
   Checkpoints,
   Debugger,
   ProjectSettings,
   Variable,
   Variables,
   VisualEditor,
   Rewards,
   DataExplorer,
   Home,
} from "@views/index"
import { Attribute } from "@/components"
import History from "@views/History.vue"
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router"
import { requireValidProject } from "../guards/control"
import { useVisualEditorStore } from "@stores/visualEditorStore"
import InstallationInstructions from "@views/InstallationInstructions.vue"
export default [
   {
      name: "Attributes",
      path: "/orgs/:orgSlug/projects/:projectId/attributes",
      component: Attributes,
      meta: {
         layout: "ProjectLayout",
      },
      beforeEnter: async (
         to: RouteLocationNormalized,
         from: RouteLocationNormalized,
         next: NavigationGuardNext,
      ) => {
         return requireValidProject(to, from, next)
      },
   },
   {
      name: "CreateAttribute",
      path: "/orgs/:orgSlug/projects/:projectId/attributes/create",
      component: CreateAttribute,
      meta: {
         layout: "ProjectLayout",
      },
      beforeEnter: async (
         to: RouteLocationNormalized,
         from: RouteLocationNormalized,
         next: NavigationGuardNext,
      ) => {
         return requireValidProject(to, from, next)
      },
   },
   {
      path: "/orgs/:orgSlug/projects/:projectId/attributes/:attributeId",
      component: Attribute,
      meta: {
         layout: "ProjectLayout",
      },
      beforeEnter: async (
         to: RouteLocationNormalized,
         from: RouteLocationNormalized,
         next: NavigationGuardNext,
      ) => {
         return requireValidProject(to, from, next)
      },
   },
   {
      name: "Variables",
      path: "/orgs/:orgSlug/projects/:projectId/variables",
      component: Variables,
      meta: {
         layout: "ProjectLayout",
      },
      beforeEnter: async (
         to: RouteLocationNormalized,
         from: RouteLocationNormalized,
         next: NavigationGuardNext,
      ) => {
         return requireValidProject(to, from, next)
      },
   },
   {
      name: "CreateVariable",
      path: "/orgs/:orgSlug/projects/:projectId/variables/create",
      component: Variable,
      meta: {
         layout: "ProjectLayout",
      },
      beforeEnter: async (
         to: RouteLocationNormalized,
         from: RouteLocationNormalized,
         next: NavigationGuardNext,
      ) => {
         return requireValidProject(to, from, next)
      },
   },
   {
      name: "EditVariable",
      path: "/orgs/:orgSlug/projects/:projectId/variables/:variableId",
      component: Variable,
      meta: {
         layout: "ProjectLayout",
      },
      beforeEnter: async (
         to: RouteLocationNormalized,
         from: RouteLocationNormalized,
         next: NavigationGuardNext,
      ) => {
         return requireValidProject(to, from, next)
      },
   },
   {
      name: "Checkpoints",
      path: "/orgs/:orgSlug/projects/:projectId/checkpoints",
      component: Checkpoints,
      meta: {
         layout: "ProjectLayout",
      },
      beforeEnter: async (
         to: RouteLocationNormalized,
         from: RouteLocationNormalized,
         next: NavigationGuardNext,
      ) => {
         return requireValidProject(to, from, next)
      },
   },
   {
      name: "History",
      path: "/orgs/:orgSlug/projects/:projectId/history",
      component: History,
      meta: {
         layout: "ProjectLayout",
      },
      beforeEnter: async (
         to: RouteLocationNormalized,
         from: RouteLocationNormalized,
         next: NavigationGuardNext,
      ) => {
         return requireValidProject(to, from, next)
      },
   },
   {
      name: "Debugger",
      path: "/orgs/:orgSlug/projects/:projectId/debugger",
      component: Debugger,
      meta: {
         layout: "ProjectLayout",
      },
      beforeEnter: async (
         to: RouteLocationNormalized,
         from: RouteLocationNormalized,
         next: NavigationGuardNext,
      ) => {
         return requireValidProject(to, from, next)
      },
   },
   {
      name: "Rewards",
      path: "/orgs/:orgSlug/projects/:projectId/rewards",
      component: Rewards,
      meta: {
         layout: "ProjectLayout",
      },
      beforeEnter: async (
         to: RouteLocationNormalized,
         from: RouteLocationNormalized,
         next: NavigationGuardNext,
      ) => {
         return requireValidProject(to, from, next)
      },
   },
   {
      name: "ProjectSettings",
      path: "/orgs/:orgSlug/projects/:projectId/settings",
      component: ProjectSettings,
      meta: {
         layout: "ProjectLayout",
      },
      beforeEnter: async (
         to: RouteLocationNormalized,
         from: RouteLocationNormalized,
         next: NavigationGuardNext,
      ) => {
         return requireValidProject(to, from, next)
      },
   },
   {
      name: "VisualEditor",
      path: "/orgs/:orgSlug/projects/:projectId/visual-editor",
      component: VisualEditor,
      meta: {
         layout: "AppLayoutDefault",
      },
      beforeEnter: async (
         to: RouteLocationNormalized,
         from: RouteLocationNormalized,
         next: NavigationGuardNext,
      ) => {
         // upon entering ../visual-editor, setup call for project settings & set the url
         // TODO: consider only reseting the url from project settings upon project change
         // not just upon entering the visual editor
         const visualEditorStore = useVisualEditorStore()
         await visualEditorStore.setupVisualEditor()
         return requireValidProject(to, from, next)
      },
   },
   {
      name: "DataExplorer",
      path: "/orgs/:orgSlug/projects/:projectId/data-explorer",
      component: DataExplorer,
      meta: {
         layout: "ProjectLayout",
      },
      beforeEnter: async (
         to: RouteLocationNormalized,
         from: RouteLocationNormalized,
         next: NavigationGuardNext,
      ) => {
         return requireValidProject(to, from, next)
      },
   },
   {
      name: "InstallationInstructions",
      path: "/orgs/:orgSlug/projects/:projectId/install",
      component: InstallationInstructions,
      meta: {
         layout: "ProjectLayout",
      },
      beforeEnter: async (
         to: RouteLocationNormalized,
         from: RouteLocationNormalized,
         next: NavigationGuardNext,
      ) => {
         return requireValidProject(to, from, next)
      },
   },
   {
      name: "ProjectHome",
      path: "/orgs/:orgSlug/projects/:projectId",
      component: Home,
      meta: {
         layout: "ProjectLayout",
      },
      beforeEnter: async (
         to: RouteLocationNormalized,
         from: RouteLocationNormalized,
         next: NavigationGuardNext,
      ) => {
         return requireValidProject(to, from, next)
      },
   },
]
