import axios, {
   AxiosInstance,
   AxiosResponse,
   InternalAxiosRequestConfig,
} from "axios"
import { clerk, getOrgId } from "./clerk"

import {
   DBVariable,
   Attribute,
   Project,
   ProjectSettings,
   UpdateProjectSettingsPayload,
   UpdateAttributePayload,
   CreateAttributePayload,
   SessionMetricsV2,
} from "../types"
import { EditableVariable } from "../types/variable"
import { SessionMetrics } from "@/types/sessionMetrics"
import { logInfo } from "@utils/logging"
import { useProjectStore } from "@stores/projectStore"
import { addBreadcrumb } from "./sentry"

const adminBackendClient: AxiosInstance = axios.create({
   baseURL: <string>import.meta.env.VITE_API_SERVER_URL,
   timeout: 5000,
})

adminBackendClient.interceptors.request.use(
   async (config: InternalAxiosRequestConfig) => {
      await clerk.load()
      const token = await clerk.session?.getToken()
      if (!token) {
         logInfo("No Clerk token found, redirecting to sign-in")
         window.location.href = "/sign-in"
      }
      addBreadcrumb({
         category: "API",
         message: `Calling Admin Backend API: ${config.url}`,
         level: "info",
      })
      if (config.headers) {
         config.headers.Authorization = `Bearer ${token}`
      }
      return config
   },
   (error) => {
      // TODO: Not sure if I should use a response interceptor
      console.log("Error in request interceptor")
      const s = error.response.status
      if (s === 401 || s === 403) {
         addBreadcrumb({
            category: "Auth",
            message: `Unauthorized: ${error.response.config.url}`,
            level: "error",
         })
         console.log("Unauthorized")
      }
   },
)

export default adminBackendClient

const getVariablesByProjectId = async (
   projectId: number,
): Promise<AxiosResponse<DBVariable[]>> => {
   const response = await adminBackendClient.get(
      `/v2/projects/${projectId}/variables`,
   )
   return response
}

const getVariable = async (id: number): Promise<AxiosResponse<DBVariable>> => {
   const { projectId } = useProjectStore()
   return await adminBackendClient.get(
      `/v2/projects/${projectId}/variables/${id}`,
   )
}

const updateVariable = async (
   id: number,
   payload: EditableVariable,
): Promise<AxiosResponse<DBVariable>> => {
   const { projectId } = useProjectStore()
   return await adminBackendClient.patch(
      `/v2/projects/${projectId}/variables/${id}`,
      payload,
   )
}

const deleteVariable = async (id: number): Promise<AxiosResponse<boolean>> => {
   const { projectId } = useProjectStore()
   return await adminBackendClient.delete(
      `/v2/projects/${projectId}/variables/${id}`,
   )
}

const createVariable = async (
   payload: EditableVariable,
): Promise<AxiosResponse<DBVariable>> => {
   const { projectId } = useProjectStore()
   return await adminBackendClient.post(
      `/v2/projects/${projectId}/variables`,
      payload,
   )
}

const getAttributesByProjectId = async (
   projectId: number,
): Promise<AxiosResponse<Attribute[]>> => {
   return await adminBackendClient.get(`/v2/projects/${projectId}/attributes`)
}

const getAttribute = async (id: number): Promise<AxiosResponse<Attribute>> => {
   const { projectId } = useProjectStore()
   return await adminBackendClient.get(
      `/v2/projects/${projectId}/attributes/${id}`,
   )
}

const updateAttribute = async (
   id: number,
   payload: UpdateAttributePayload,
): Promise<AxiosResponse<Attribute>> => {
   const { projectId } = useProjectStore()
   return await adminBackendClient.patch(
      `/v2/projects/${projectId}/attributes/${id}`,
      payload,
   )
}

const deleteAttribute = async (id: number): Promise<AxiosResponse<boolean>> => {
   const { projectId } = useProjectStore()
   return await adminBackendClient.delete(
      `/v2/projects/${projectId}/attributes/${id}`,
   )
}

const createAttribute = async (
   payload: CreateAttributePayload,
): Promise<AxiosResponse<Attribute>> => {
   const { projectId } = useProjectStore()
   return await adminBackendClient.post(
      `/v2/projects/${projectId}/attributes`,
      payload,
   )
}

const getProjectSettings = async (
   projectId: number,
): Promise<AxiosResponse<ProjectSettings>> => {
   return await adminBackendClient.get(`/v2/projects/${projectId}/settings`)
}

const updateActiveProjectSettings = async (
   payload: UpdateProjectSettingsPayload,
): Promise<AxiosResponse<ProjectSettings>> => {
   const { projectId } = useProjectStore()
   return await adminBackendClient.patch(
      `/v2/projects/${projectId}/settings`,
      payload,
   )
}

const getProjectsByActiveOrg = async (): Promise<AxiosResponse<Project[]>> => {
   return await adminBackendClient.get(`/v2/projects`)
}

const getSessionMetricsByActiveProject = async (
   startDate: number,
   endDate: number | null,
   aggLevel: string,
): Promise<AxiosResponse<SessionMetrics>> => {
   const { projectId } = useProjectStore()
   const response = await adminBackendClient.get<SessionMetrics>(
      `/v1/session-stats/project/${projectId}`,
      {
         params: {
            start_date: startDate,
            end_date: endDate,
            agg_level: aggLevel,
         },
      },
   )
   return response
}

const getDashboardByActiveProject = async (
   startDate: number,
   endDate: number | null,
   aggLevel: string,
   variableIds?: number[],
   startAt?: number,
   numRows?: number,
   sortBy?: string,
   descending?: boolean,
): Promise<AxiosResponse<SessionMetricsV2>> => {
   const { projectId } = useProjectStore()
   const params = {
      start_date: startDate,
      end_date: endDate,
      agg_level: aggLevel,
      variable_id: variableIds,
      start_at: startAt,
      num_rows: numRows,
      sort_by: sortBy,
      descending: descending,
   }
   const response = await adminBackendClient.get<SessionMetricsV2>(
      `/v1/dashboard/project/${projectId}`,
      {
         params: params,
         paramsSerializer: {
            indexes: null, // no brackets at all
         },
      },
   )
   return response
}

const createProject = async (
   humanReadableName: string,
   orgId: string,
): Promise<AxiosResponse<Project>> => {
   return await adminBackendClient.post(`/v2/projects`, {
      humanReadableName: humanReadableName,
      organizationId: orgId,
   })
}

const deleteProject = async (
   projectId: number,
): Promise<AxiosResponse<boolean>> => {
   return await adminBackendClient.delete(`/v2/projects/${projectId}`)
}

const updateProject = async (
   projectId: number,
   orgId: string,
   humanReadableName: string,
): Promise<AxiosResponse<Project>> => {
   return await adminBackendClient.patch(`/v2/projects/${projectId}`, {
      humanReadableName: humanReadableName,
      organizationId: orgId,
   })
}

const updateCurrentProject = async (
   humanReadableName: string,
): Promise<AxiosResponse<Project>> => {
   const { projectId } = useProjectStore()
   const orgId = await getOrgId()
   if (!projectId) {
      throw new Error("No project selected")
   }
   if (!orgId) {
      throw new Error("No orgId found")
   }
   return await updateProject(projectId, orgId, humanReadableName)
}

export {
   adminBackendClient,
   getVariable,
   getAttribute,
   getVariablesByProjectId,
   getAttributesByProjectId,
   updateAttribute,
   deleteAttribute,
   createAttribute,
   updateVariable,
   deleteVariable,
   createVariable,
   getProjectSettings,
   updateActiveProjectSettings,
   getProjectsByActiveOrg,
   getSessionMetricsByActiveProject,
   getDashboardByActiveProject,
   createProject,
   deleteProject,
   updateProject,
   updateCurrentProject,
}
