import { CreateProject, Org, Orgs, SelectOrg } from "@views/index"
import {
   requireActiveSubscription,
   requireValidProject,
} from "../guards/control"
import { requireValidOrg } from "../guards/orgs"
import { RouteLocationNormalized, NavigationGuardNext } from "vue-router"
import ProjectLayout from "@/layouts/ProjectLayout.vue"
import { getOrg } from "@/services/clerk"
import { useProjectStore } from "@stores/projectStore"

export default [
   {
      path: "/orgs/select",
      name: "SelectOrg",
      component: SelectOrg,
      beforeEnter: async (
         to: RouteLocationNormalized,
         from: RouteLocationNormalized,
         next: NavigationGuardNext,
      ) => {
         return requireValidOrg(to, from, next)
      },
   },
   {
      name: "CreateProject",
      path: "/orgs/:orgSlug/projects/create",
      component: CreateProject,
      meta: {
         layout: "OrgsLayout",
      },
      beforeEnter: async (
         to: RouteLocationNormalized,
         from: RouteLocationNormalized,
         next: NavigationGuardNext,
      ) => {
         return requireActiveSubscription(to, from, next)
      },
   },
   {
      path: "/orgs/:orgSlug/projects",
      name: "OrgProjects",
      component: ProjectLayout,
      meta: {
         layout: "OrgsLayout",
      },
      beforeEnter: async (
         to: RouteLocationNormalized,
         from: RouteLocationNormalized,
         next: NavigationGuardNext,
      ) => {
         return requireValidProject(to, from, next)
      },
   },
   {
      path: "/orgs/setup",
      name: "OrgsSetup",
      component: Orgs,
      meta: {
         layout: "OrgsLayout",
      },
      beforeEnter: async (
         to: RouteLocationNormalized,
         from: RouteLocationNormalized,
         next: NavigationGuardNext,
      ) => {
         const projectStore = useProjectStore()
         const org = await getOrg()
         projectStore.projectId = null
         projectStore.projects = []
         return next({ name: "OrgHome", params: { orgSlug: org!.slug } })
      },
   },
   {
      path: "/orgs/:orgSlug",
      name: "OrgHome",
      component: Org,
      meta: {
         layout: "OrgsLayout",
      },
      beforeEnter: async (
         to: RouteLocationNormalized,
         from: RouteLocationNormalized,
         next: NavigationGuardNext,
      ) => {
         const projectStore = useProjectStore()
         await projectStore.getActiveOrgProjects()
         return requireValidOrg(to, from, next)
      },
   },
]
