<template>
   <QCard class="graph-card">
      <QCardSection class="bg-primary">
         <div class="text-h5 text-white">Variable Snapshot</div>
      </QCardSection>
      <div class="q-pa-md q-gutter-y-md flex flex-row" style="gap: 1em">
         <QBtnDropdown
            color="primary"
            :label="activeVariableFilter.humanReadableName"
            outline
         >
            <QList>
               <template
                  v-for="variable in projectStore.variables"
                  v-bind:key="variable.id"
               >
                  <QItem
                     clickable
                     v-close-popup
                     @click="onVariableClick(variable.id)"
                  >
                     <QItemSection>
                        <QItemLabel>{{
                           variable.humanReadableName
                        }}</QItemLabel>
                     </QItemSection>
                  </QItem>
               </template>
            </QList>
         </QBtnDropdown>
         <QBtnDropdown
            color="primary"
            :label="secondaryVariableFilter.humanReadableName"
            outline
         >
            <QList>
               <QItem
                  clickable
                  v-close-popup
                  @click="onSecondaryVariableClick(-1)"
               >
                  <QItemSection>
                     <QItemLabel>None</QItemLabel>
                  </QItemSection>
               </QItem>
               <template
                  v-for="variable in projectStore.variables.filter(
                     (x) => x.id != activeVariableFilter.id,
                  )"
                  v-bind:key="variable.id"
               >
                  <QItem
                     clickable
                     v-close-popup
                     @click="onSecondaryVariableClick(variable.id)"
                  >
                     <QItemSection>
                        <QItemLabel>{{
                           variable.humanReadableName
                        }}</QItemLabel>
                     </QItemSection>
                  </QItem>
               </template>
            </QList>
         </QBtnDropdown>
         <QToggle
            size="sm"
            v-model="conversionRateOrServingRate"
            :val="conversionRateOrServingRate"
            label="Serving Rate or Conversion Rate"
         />
      </div>
      <QCardSection class="col" horizontal>
         <QTable
            class="col-8"
            id="combinations-table"
            v-if="dashboardStore.hasVariants && projectStore.variables"
            dense
            :rows="rows"
            :columns="columns"
            row-key="variant"
            width="100%"
            :pagination="{
               page: 1,
               rowsPerPage: 10,
               descending: false,
            }"
            :hide-pagination="true"
            flat
         >
            <template v-slot:body-cell-variant="props">
               <QTd :props="props">
                  <span
                     v-if="props.row.variantColor"
                     class="indicator"
                     :style="{ backgroundColor: props.row.variantColor }"
                  ></span>
                  {{ props.row.variant }}
               </QTd>
            </template>

            <template v-slot:header-cell-conversion-rate="props">
               <QTh :props="props"> Conversion Rate </QTh>
            </template>
            <template v-slot:no-data="{ icon, filter }">
               <div
                  id="no-data-container"
                  class="full-width row flex-center text-accent q-ma-lg"
               >
                  <QIcon
                     size="2em"
                     color="primary"
                     :name="filter ? 'filter_b_and_w' : icon"
                  />
                  <span id="no-data"
                     >No data match your query. Please change your filters and
                     try again.</span
                  >
               </div>
            </template>
         </QTable>
         <QSeparator vertical></QSeparator>
         <!--         <SnapshotDoughnut-->
         <!--            v-if="secondaryVariableFilter.id < 0"-->
         <!--            ref="doughnut"-->
         <!--            class="col-4"-->
         <!--         ></SnapshotDoughnut>-->
         <!--         <div v-if="secondaryVariableFilter.id >= 0" id="chart">-->
         <div id="chart">
            <apexchart
               v-if="series"
               type="radar"
               height="350"
               :options="chartOptions"
               :series="series"
            ></apexchart>
         </div>
      </QCardSection>
   </QCard>
</template>
<script setup lang="ts">
import { computed, ComputedRef, ref } from "vue"
import { QTable, QTableColumn } from "quasar"
import { useDashboardStore } from "@stores/dashboardStore"
import { useProjectStore } from "@stores/projectStore"

import { formatPercentTwoDecimals } from "./formatters"
import { VariantForRender } from "@/types/variable-snapshot"
import { VariableValue } from "@/types/variable"
import { ApexOptions } from "apexcharts"
import { ApexSeries } from "@/types/graphs"
import { getApexColor } from "@utils/chartColors"

const dashboardStore = useDashboardStore()
const projectStore = useProjectStore()

const variantColumn: QTableColumn = {
   name: "variant",
   label: "Variant",
   align: "center",
   field: (row: VariantForRender) => row.variant,
   sortable: true,
}

const sessionsColumn: QTableColumn = {
   name: "sessions",
   label: "Sessions",
   align: "left",
   field: (row: VariantForRender) => row.sessions,
   sortable: true,
}
const conversionsColumn: QTableColumn = {
   name: "conversions",
   label: "Conversions",
   align: "left",
   field: (row: VariantForRender) => row.conversions,
   sortable: true,
}

const conversionRateColumnLabel = computed(() => {
   if (conversionRateOrServingRate.value) {
      return `Conversion Rate`
   } else {
      return `Serving Rate`
   }
})

const conversionRateColumn: QTableColumn = {
   name: "conversion-rate",
   label: "Conversion Rate",
   align: "left",
   field: (row: VariantForRender) => row.conversionRate,
   sortable: true,
   format: (v: number) => formatPercentTwoDecimals(v),
}

const trafficDistributionColumn: QTableColumn = {
   name: "traffic-distribution",
   label: "Traffic %",
   align: "left",
   field: (row: VariantForRender) => row.trafficPct,
   sortable: true,
   format: (v: number) => formatPercentTwoDecimals(v),
}

const columns = computed(() => {
   const variables = dashboardStore.variable_snapshot
      ? dashboardStore.variable_snapshot.ids
      : []
   return [
      variantColumn,
      sessionsColumn,
      conversionsColumn,
      trafficDistributionColumn,
      conversionRateColumn,
      ...variables.map((vId) => {
         return <QTableColumn>{
            name: projectStore.getName(vId),
            label: projectStore.getName(vId),
            align: "left",
            field: (row: VariantForRender) => {
               return row.variables.find((x) => x.variableId == vId)?.value
            },
            sortable: true,
            style: "max-width: 400px; text-wrap: wrap;",
         }
      }),
   ]
})

const defaultConversionRate = computed(() => {
   const defaultConversionRates =
      dashboardStore.time_series?.default_conversion_rate
   if (!defaultConversionRates) {
      return 0
   }
   return defaultConversionRates[defaultConversionRates.length - 1]
})

const rows = computed(() => {
   const variantStats = dashboardStore.variable_snapshot?.variants
   if (!variantStats) {
      return []
   }
   let agg: VariantForRender[] = []
   variantStats.forEach((variantStat, i) => {
      const combinationNumber = variantStat.variant
      const variables = combinationNumber.split(",")
      const vars = variables.map((variable) => {
         const spl = variable.split(":")
         const id: number = +spl[0]
         const index: number = +spl[1]
         return <VariableValue>{
            variableId: id,
            humanReadableName: projectStore.getName(id),
            value: projectStore.getValue(id, index),
         }
      })

      const newComboForRender = <VariantForRender>{
         variant: combinationNumber,
         conversionRate: variantStat.conversion_rate,
         trafficPct: variantStat.traffic_pct,
         variantColor: getApexColor(i),
         statSig: {
            lb: variantStat.cr_lb,
            ub: variantStat.cr_ub,
            defaultConversionRate: defaultConversionRate.value,
         },
         conversions: variantStat.conversions,
         sessions: variantStat.sessions,
         variables: vars,
      }

      agg.push(newComboForRender)
   })
   return agg
})
const activeVariableFilter = ref({
   humanReadableName: projectStore.variables.find(
      (x): boolean => dashboardStore.variable_snapshot?.ids[0] == x.id,
   )
      ? projectStore.variables.find(
           (x): boolean => dashboardStore.variable_snapshot?.ids[0] == x.id,
        )!.humanReadableName
      : "None Selected",
   id: dashboardStore.variable_snapshot
      ? dashboardStore.variable_snapshot!.ids[0]
      : 0,
})

const secondaryVariableFilter = ref({
   humanReadableName: "None Selected",
   id: -1,
})

const onVariableClick = async (id: number) => {
   activeVariableFilter.value = {
      humanReadableName: projectStore.variables.find((x): boolean => id == x.id)
         ? projectStore.variables.find((x): boolean => id == x.id)!
              .humanReadableName
         : "None Selected",
      id: id,
   }
   if (secondaryVariableFilter.value.id != -1) {
      const variableIds = [id, secondaryVariableFilter.value.id]
      await dashboardStore.updateDashboard(variableIds)
   } else {
      const variableIds = [id]
      await dashboardStore.updateDashboard(variableIds)
   }
}
const onSecondaryVariableClick = async (id: number) => {
   secondaryVariableFilter.value = {
      humanReadableName: projectStore.variables.find((x): boolean => id == x.id)
         ? projectStore.variables.find((x): boolean => id == x.id)!
              .humanReadableName
         : "None Selected",
      id: id,
   }

   if (id != -1) {
      activeVariableFilter.value.id
      const variableIds = [activeVariableFilter.value.id, id]
      await dashboardStore.updateDashboard(variableIds)
   } else {
      const variableIds = [activeVariableFilter.value.id]
      await dashboardStore.updateDashboard(variableIds)
   }
}

const chartOptions: ComputedRef<ApexOptions> = computed(() => {
   return {
      chart: {
         height: 450,
         type: "radar",
      },
      dataLabels: {
         enabled: false,
      },
      markers: {
         size: 0,
      },
      xaxis: {
         categories: dashboardStore.hour_of_day?.[0].hour_of_day,
      },
      yaxis: {
         labels: {
            formatter: (value: number) => {
               return `${value * 100}%`
            },
         },
      },
      title: {
         text: conversionRateColumnLabel.value + " by Hour of Day (UTC)",
      },
   }
})

const series: ComputedRef<ApexSeries[]> = computed(() => {
   return (
      dashboardStore.hour_of_day?.map((hod) => {
         let color = undefined
         const match = rows.value.find((row) => row.variant === hod.variant)
         if (match) {
            color = match.variantColor
         }
         return {
            name: hod.variant,
            data: conversionRateOrServingRate.value
               ? hod.conversion_rate
               : hod.traffic_pct,
            color,
         } as ApexSeries
      }) || []
   )
})
const conversionRateOrServingRate = ref(false)
</script>
<style lang="scss" scoped>
#combinations-table-container {
   margin-top: 20px;
   margin-bottom: 20px;
   width: 100%;
   .q-td {
      text-align: left;
   }
}
.toggles-filters-container {
   display: flex;
   justify-content: flex-start;
   margin-bottom: 2em;
   // border: 1px solid #e0e0e0;
   // border-radius: 5px;
}
#no-data {
   font-size: 1.25em;
   color: $primary;
   font-weight: bold;
   margin-left: 10px;
}
#no-data-container {
   min-height: 30vh;
}
#selector-container {
   display: flex;
   flex-direction: column;
   gap: 10px;
   padding: 20px;
}
.indicator {
   display: inline-block;
   width: 10px;
   height: 10px;
   border-radius: 50%;
   margin-right: 5px;
}
</style>
