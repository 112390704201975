<template>
   <div id="combinations-table-container">
      <QCard>
         <QCardSection class="bg-primary q-pa-sm">
            <div class="text-h6 text-white">
               Observed Combinations
               <HeaderTooltip
                  tooltip-text="Combinations with at least one session in the current project version. If you have more combinations, expect to see more of them after the next analytics run."
               />
            </div>
         </QCardSection>
         <QCardSection>
            <QTable
               id="combinations-table"
               v-if="
                  dashboardStore.hasVariants &&
                  projectStore.variables &&
                  pagination
               "
               dense
               :rows="rows"
               :columns="columns"
               row-key="id"
               width="100%"
               v-model:pagination="pagination"
               binary-state-sort
               flat
               :loading="loading"
               @request="onRequest"
               :rows-per-page-options="[10, 25, 50]"
            >
               <template v-slot:header-cell-conversion_rate="props">
                  <QTh :props="props">
                     {{ longWindowConversionRateColumnLabel }}
                  </QTh>
               </template>
               <template v-slot:header-cell-cr_improvement="props">
                  <QTh :props="props">
                     {{ improvementColumnLabel }}
                  </QTh>
               </template>
               <template v-slot:header-cell-statistical-significance="props">
                  <QTh :props="props">
                     {{ longWindowStatSigColumnLabel }}
                  </QTh>
               </template>
               <template v-slot:header-cell="props">
                  <QTh :props="props">
                     {{ props.col.label }}
                  </QTh>
               </template>
               <template
                  v-slot:body-cell-statistical-significance="props: unknown"
               >
                  <QTd>
                     <StatSigRenderer
                        :val="(props as QTableStatSigPropPatch).value"
                     />
                  </QTd>
               </template>

               <template v-slot:body-cell-conversions="props: unknown">
                  <QTd>
                     <IntegerRenderer
                        :val="(props as QTableIntegerPropPatch).value"
                     />
                  </QTd>
               </template>
               <template v-slot:body-cell-id="props: unknown">
                  <QTd>
                     <CombinationNumberRenderer
                        :val="(props as QTableCombinationNumberPropPatch).value"
                     />
                  </QTd>
               </template>
               <template v-slot:body-cell="props: unknown">
                  <QTd
                     ><TruncatedRenderer
                        :val="
                           String((props as QTableTruncatedPropPatch).value)
                        "
                  /></QTd>
               </template>
               <template v-slot:body-cell-moving_average_3="props: unknown">
                  <QTd>
                     <ImprovementRenderer
                        :val="(props as QTableImprovementPropPatch).value"
                        :comparisonVal="
                           (props as QTableImprovementPropPatch).row
                              .moving_average_7 || 0
                        "
                        tooltipText="Compared to 7-day moving average"
                     />
                  </QTd>
               </template>
               <template v-slot:body-cell-moving_average_7="props: unknown">
                  <QTd>
                     <ImprovementRenderer
                        :val="(props as QTableImprovementPropPatch).value"
                        :comparisonVal="
                           (props as QTableImprovementPropPatch).row
                              .moving_average_14 || 0
                        "
                        tooltipText="Compared to 14-day moving average"
                     />
                  </QTd>
               </template>
               <template v-slot:body-cell-moving_average_14="props: unknown">
                  <QTd>
                     <ImprovementRenderer
                        :val="(props as QTableImprovementPropPatch).value"
                        :comparisonVal="
                           (props as QTableImprovementPropPatch).row
                              .moving_average_30 || 0
                        "
                        tooltipText="Compared to 30-day moving average"
                     />
                  </QTd>
               </template>
               <template v-slot:no-data="{ icon, filter }">
                  <div
                     id="no-data-container"
                     class="full-width row flex-center text-accent q-ma-lg"
                  >
                     <QIcon
                        size="2em"
                        color="primary"
                        :name="filter ? 'filter_b_and_w' : icon"
                     />
                     <span id="no-data"
                        >No data match your query. Please change your filters
                        and try again.
                     </span>
                  </div>
               </template>
            </QTable>
            <CombinationsTablePlaceholderV2 v-else />
         </QCardSection>
      </QCard>
   </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue"
import { QTable, QTableColumn, QTableProps } from "quasar"
import { useDashboardStore } from "@stores/dashboardStore"
import { useProjectStore } from "@stores/projectStore"
import CombinationsTablePlaceholderV2 from "./CombinationsTablePlaceholderV2.vue"
import {
   CombinationForRender,
   StatSigForRender,
} from "../../types/combinations-table"
import TruncatedRenderer from "./TruncatedRenderer.vue"
import StatSigRenderer from "./StatSigRenderer.vue"
import CombinationNumberRenderer from "./CombinationNumberRenderer.vue"
import {
   formatPercentTwoDecimals,
   formatPercentTwoDecimalsWithSign,
} from "./formatters"
import IntegerRenderer from "@components/combinations-table/IntegerRenderer.vue"
import HeaderTooltip from "@components/dashboard/HeaderTooltip.vue"
import ImprovementRenderer from "@components/combinations-table-v2/ImprovementRenderer.vue"

const loading = ref(false)

const dashboardStore = useDashboardStore()
const projectStore = useProjectStore()

type QTableStatSigPropPatch = {
   [K in keyof QTableProps]: QTableProps[K]
} & {
   value: StatSigForRender
}
type QTableTruncatedPropPatch = {
   [K in keyof QTableProps]: QTableProps[K]
} & {
   value: string | number
}
type QTableCombinationNumberPropPatch = {
   [K in keyof QTableProps]: QTableProps[K]
} & {
   value: string | number
}
type QTableIntegerPropPatch = {
   [K in keyof QTableProps]: QTableProps[K]
} & {
   value: string | number
}
type QTableImprovementPropPatch = {
   [K in keyof QTableProps]: QTableProps[K]
} & {
   value: number
   row: CombinationForRender
}

const lookBackWindowDays = computed(() => {
   if (!dashboardStore.daysBack) {
      return 180
   }
   return dashboardStore.daysBack + 180
})

const idColumn: QTableColumn = {
   name: "id",
   label: "#",
   align: "left",
   field: (row: CombinationForRender) => row.number,
   sortable: true,
}

const sessionsColumn: QTableColumn = {
   name: "sessions",
   label: "Window Sessions",
   align: "left",
   field: (row: CombinationForRender) => row.sessions,
   sortable: true,
}
const conversionsColumn: QTableColumn = {
   name: "conversions",
   label: "Window Conversions",
   align: "left",
   field: (row: CombinationForRender) => row.conversions,
   sortable: true,
}

const longWindowTrafficDistributionColumnLabel = computed(() => {
   return `${lookBackWindowDays.value}-day Traffic %`
})

const longWindowConversionRateColumnLabel = computed(() => {
   return `${lookBackWindowDays.value}-day CR`
})

const windowConversionRateColumn: QTableColumn = {
   name: "window_cr",
   label: "Window CR",
   align: "left",
   field: (row: CombinationForRender) => row.window_cr,
   sortable: false,
   format: (v: number) => formatPercentTwoDecimals(v),
}

const movingAverage3ConversionRateColumn: QTableColumn = {
   name: "moving_average_3",
   label: "3-Day CR",
   align: "left",
   field: (row: CombinationForRender) => row.moving_average_3,
   sortable: false,
}

const movingAverage7ConversionRateColumn: QTableColumn = {
   name: "moving_average_7",
   label: "7-Day CR",
   align: "left",
   field: (row: CombinationForRender) => row.moving_average_7,
   sortable: false,
}

const movingAverage14ConversionRateColumn: QTableColumn = {
   name: "moving_average_14",
   label: "14-Day CR",
   align: "left",
   field: (row: CombinationForRender) => row.moving_average_14,
   sortable: false,
}

const movingAverage30ConversionRateColumn: QTableColumn = {
   name: "moving_average_30",
   label: "30-Day CR",
   align: "left",
   field: (row: CombinationForRender) => row.moving_average_30,
   sortable: false,
   format: (v: number) => formatPercentTwoDecimals(v),
}

const longWindowConversionRateColumn: QTableColumn = {
   name: "conversion_rate", // change to long_window_conversion_rate in API and UI in future
   label: longWindowConversionRateColumnLabel.value,
   align: "left",
   field: (row: CombinationForRender) => row.conversionRate,
   sortable: true,
   format: (v: number) => formatPercentTwoDecimals(v),
}

const improvementColumnLabel = computed(() => {
   return `${lookBackWindowDays.value}-day CR Δ`
})

const longWindowConversionRateImprovementColumn: QTableColumn = {
   name: "cr_improvement", // change to long_window_conversion_rate_improvement in API and UI in future
   label: improvementColumnLabel.value,
   align: "left",
   field: (row: CombinationForRender) => row.conversionRateImprovement,
   sortable: true,
   format: (v: number) => formatPercentTwoDecimalsWithSign(v),
}

const longWindowStatSigColumnLabel = computed(() => {
   return `${lookBackWindowDays.value}-day 99% Confidence Interval`
})

const longWindowStatSigColumn: QTableColumn = {
   name: "statistical-significance", // change to long_window_stat_sig in API and UI in future
   label: longWindowStatSigColumnLabel.value,
   align: "left",
   field: (row: CombinationForRender) => row.statSig,
   sortable: false,
}

const longWindowTrafficDistributionColumn: QTableColumn = {
   name: "traffic_pct", // change to long_window_traffic_pct in API and UI in future
   label: longWindowTrafficDistributionColumnLabel.value,
   align: "left",
   field: (row: CombinationForRender) => row.trafficDistribution,
   sortable: true,
   format: (v: number) => formatPercentTwoDecimals(v),
}

const columns = computed(() => {
   const variables = projectStore.variables
   return [
      idColumn,
      sessionsColumn,
      conversionsColumn,
      windowConversionRateColumn,
      movingAverage3ConversionRateColumn,
      movingAverage7ConversionRateColumn,
      movingAverage14ConversionRateColumn,
      movingAverage30ConversionRateColumn,
      longWindowTrafficDistributionColumn,
      longWindowConversionRateColumn,
      longWindowConversionRateImprovementColumn,
      longWindowStatSigColumn,
      ...variables.map((variable) => {
         return <QTableColumn>{
            name: variable.humanReadableName,
            label: variable.humanReadableName,
            align: "left",
            field: (row: CombinationForRender) =>
               row[variable.humanReadableName],
            sortable: false, // would have to be sorted server-side
            style: "max-width: 400px; text-wrap: wrap;",
         }
      }),
   ]
})

const calculateConversionRateImprovement = (
   combinationConversionRate: number | undefined,
   defaultConversionRate: number,
) => {
   if (combinationConversionRate) {
      return (
         (combinationConversionRate - defaultConversionRate) /
         defaultConversionRate
      )
   }

   return 0
}

const defaultConversionRate = computed(() => {
   const defaultConversionRate = dashboardStore.stats?.default_overall_rate
   if (!defaultConversionRate) {
      return 0
   }
   return defaultConversionRate
})

const rows = computed(() => {
   const combinationStats = dashboardStore.topN?.combinations
   if (!combinationStats) {
      return []
   }
   let agg: CombinationForRender[] = []
   combinationStats.forEach((combinationStat) => {
      const combinationNumber = combinationStat.id || 0
      const trafficPercent =
         combinationStat.sessions / dashboardStore.stats!.sessions
      const combo = projectStore.decodeCombination(combinationNumber)
      const newComboForRender = <CombinationForRender>{
         number: combinationNumber,
         conversionRate: combinationStat.conversion_rate
            ? combinationStat.conversion_rate
            : 0,
         window_cr: combinationStat.window_cr || 0,
         moving_average_3: combinationStat.moving_average_3 || 0,
         moving_average_7: combinationStat.moving_average_7 || 0,
         moving_average_14: combinationStat.moving_average_14 || 0,
         moving_average_30: combinationStat.moving_average_30 || 0,
         trafficDistribution: trafficPercent,
         conversionRateImprovement: calculateConversionRateImprovement(
            combinationStat.conversion_rate,
            defaultConversionRate.value!,
         ),
         statSig: {
            lb: combinationStat.cr_lb ? combinationStat.cr_lb : 0,
            ub: combinationStat.cr_ub ? combinationStat.cr_ub : 0,
            defaultConversionRate: defaultConversionRate.value,
         },
         conversions: combinationStat.conversions
            ? combinationStat.conversions
            : 0,
         sessions: combinationStat.sessions,
      }
      combo.forEach((variable) => {
         newComboForRender[variable.humanReadableName] = variable.value
      })

      agg.push(newComboForRender)
   })
   return agg
})

const pagination = ref({
   sortBy: "id",
   descending: false,
   page: 1,
   rowsPerPage: 10,
   rowsNumber: dashboardStore.topN?.total_combos,
})
const onRequest = async (props: {
   pagination: {
      page: number
      rowsPerPage: number
      sortBy: string
      descending: boolean
   }
}) => {
   const { page, rowsPerPage, sortBy, descending } = props.pagination

   loading.value = true

   // update rowsCount with appropriate value
   pagination.value.rowsNumber = dashboardStore.topN?.total_combos
      ? dashboardStore.topN?.total_combos
      : 25

   const startAt = rowsPerPage * (page - 1)
   await dashboardStore.getCombos(startAt, rowsPerPage, sortBy, descending)

   // update local pagination object
   pagination.value.page = page
   pagination.value.rowsPerPage = rowsPerPage
   pagination.value.sortBy = sortBy
   pagination.value.descending = descending

   // ...and turn off loading indicator
   loading.value = false
}
</script>
<style lang="scss" scoped>
#combinations-table-container {
   margin-top: 20px;
   margin-bottom: 20px;
   width: 100%;
   .q-td {
      text-align: left;
   }
}
.toggles-filters-container {
   display: flex;
   justify-content: flex-start;
   margin-bottom: 2em;
   // border: 1px solid #e0e0e0;
   // border-radius: 5px;
}
#no-data {
   font-size: 1.25em;
   color: $primary;
   font-weight: bold;
   margin-left: 10px;
}
#no-data-container {
   min-height: 30vh;
}
</style>
